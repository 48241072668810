import Image from 'next/image'

import { EventCta } from '../../components/event-cta/event-cta'

import CSS from './hero-brand.module.css'

export function HeroBrand ({ children, theme }) {
    return (
        
        <section className={`${CSS.layout} ${ theme ? theme : `bg-primary`}`} >

            <div className={CSS.brandPattern}>
                <Image src={`/assets/brand/motif-banner.jpg`} layout={`fill`} width={1920} height={1000} alt="" priority />
            </div>

            <div className={`${CSS.panel} block bg-extra-dark`}>

                <p className={CSS.ahif}>AHIF</p>
                <p className={`${CSS.title} mb-md`}>Africa Hospitality Investment Forum</p>

                <EventCta />
            </div>

            <section className={`${CSS.content} block ${ theme ? theme : `bg-primary`}`}>
                { children }
            </section>

        </section>

    )
}