import { ContentCard } from "../../../../fuselage/components/content-card/content-card"
import { ArrowButton } from "../../../../fuselage/components/arrow-button/arrow-button"

import CSS from './featured-content.module.css'

export const FeaturedContent = ({ posts }) => {

    return (

                
            <div className={`${CSS.content} block bg-primary`}>
                <div className='bl-1 bc-white pl-md'>
                    <div className={`${CSS.headingGrid} container flex gap-sm ai-center`}>
                        <p className="fs-1 fw-700 lh-1">Latest press releases</p>
                        <p><ArrowButton href={`/content-library/category/press-room`} theme={{ arrow: `white`, border: `white`, label: `white`}}>See all press releases</ArrowButton></p>
                    </div>

                    <div className="container columns-3 gap-md">
                        {
                            posts &&
                            posts.map( post => {
                                return (
                                    <ContentCard
                                        key={post.id}
                                        url={`/content-library/${post.slug}`}
                                        heroUrl={post.contentLibraryHero && post.contentLibraryHero[0] ? post.contentLibraryHero[0].url : null}
                                        heading={post.title}
                                        type={``}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>


    )
}
